import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { type MarketName } from '@src/domain/api/x10/common'
import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'

const emptyData: MarketName[] = []

const useFavoriteMarketsStore = create<{
  favoriteMarkets: MarketName[]
}>()(
  persist(
    () => ({
      favoriteMarkets: emptyData,
    }),
    {
      name: getStorageKey('favorite-markets'),
    },
  ),
)

export const useFavoriteMarkets = () =>
  useFavoriteMarketsStore((state) => state.favoriteMarkets)

useFavoriteMarkets.addFavoriteMarket = (market: MarketName) =>
  useFavoriteMarketsStore.setState((prev) => {
    const newFavoriteMarkets = [...prev.favoriteMarkets, market]
    return { favoriteMarkets: newFavoriteMarkets }
  })

useFavoriteMarkets.removeFavoriteMarket = (removedMarket: MarketName) =>
  useFavoriteMarketsStore.setState((prev) => ({
    favoriteMarkets: prev.favoriteMarkets.filter((market) => market !== removedMarket),
  }))
