import { Suspense, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Scrollable, SvgIcon, Tabs } from '@x10/lib-ui-kit/components'

import { DefaultMarketCategory } from '@src/domain/core/constants'
import { useMarketsCategories } from '@src/domain/core/ui/hooks/use-markets-categories'

type Props = {
  selectedCategory: string
  onCategoryChange: (category: string) => void
}

const MarketsCategorySelector: FC<Props> = ({ selectedCategory, onCategoryChange }) => {
  const marketsCategories = useMarketsCategories()

  return (
    <Tabs.Root
      size="md"
      value={selectedCategory}
      onValueChange={({ value }) => onCategoryChange(value)}
    >
      <Scrollable scrollX>
        <Tabs.List>
          {marketsCategories.map((category) => (
            <Tabs.Trigger key={category} value={category}>
              {category === DefaultMarketCategory.All ? (
                <FormattedMessage
                  id="workspace.trade.widget.chart.market-selector.filter.tab.all.title"
                  defaultMessage="All"
                />
              ) : category === DefaultMarketCategory.Favorites ? (
                <HStack>
                  <SvgIcon.SvgIconStarEmpty size={16} />

                  <FormattedMessage
                    id="workspace.trade.widget.chart.market-selector.filter.tab.favorites.title"
                    defaultMessage="Favorites"
                  />
                </HStack>
              ) : (
                category
              )}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Scrollable>
    </Tabs.Root>
  )
}

export const MarketsCategorySelectorWrapper: FC<Props> = (props) => (
  <Suspense>
    <MarketsCategorySelector {...props} />
  </Suspense>
)
