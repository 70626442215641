import { useMemo, useRef } from 'react'
import { get, groupBy, type Dictionary } from 'lodash'
import type { ColumnSort } from '@tanstack/react-table'

import { checkRequired, Decimal } from '@x10/lib-core/utils'

import { useSuspendedMarkets } from '@src/domain/api/hooks/markets-info/use-markets'
import { compareDecimals } from '@src/domain/core/utils/sorting'

import { DefaultMarketCategory } from '../../constants'
import { useFavoriteMarkets } from '../../store/use-favorite-markets'
import { type TableMarket } from '../../types/common'

const getHashKey = (market: TableMarket) =>
  `${market.name}${market.favorite ? '-favorite' : ''}`

const compareMarketsBySorting =
  (sorting: ColumnSort) => (valueA: TableMarket, valueB: TableMarket) => {
    let sortingId = sorting.id

    if (sorting.id === 'dailyPriceChange') {
      sortingId = 'marketStats.dailyPriceChangePercentage'
    }

    const columnValueA = get<unknown, string>(valueA, sortingId)
    const columnValueB = get<unknown, string>(valueB, sortingId)

    let result = 0

    if (Decimal.isDecimal(columnValueA) && Decimal.isDecimal(columnValueB)) {
      result = compareDecimals(columnValueA, columnValueB)
    } else if (typeof columnValueA === 'string' && typeof columnValueB === 'string') {
      result = columnValueA.localeCompare(columnValueB)
    }

    return result * (sorting.desc ? -1 : 1)
  }

const compareMarketsByFavorite = (valueA: TableMarket, valueB: TableMarket) => {
  if ((valueA.favorite && valueB.favorite) || (!valueA.favorite && !valueB.favorite)) {
    return 0
  }

  if (valueA.favorite) {
    return -1
  }

  return 1
}

export const useTableMarketsData = (sorting: ColumnSort) => {
  const favoriteMarkets = useFavoriteMarkets()
  const { data: markets } = useSuspendedMarkets()

  const sortedMarketsIndexHashRef = useRef<Record<string, number>>({})
  const shouldResetIndexHashRef = useRef(false)
  const defaultSortingChangedRef = useRef(false)
  const prevSortingRef = useRef<ColumnSort>(sorting)

  // Sort markets by `sorting` and `favorite` and preserve the order of markets
  // if `sorting` doesn't change.
  const sortedMarkets = useMemo<TableMarket[]>(() => {
    const sortingChanged =
      prevSortingRef.current?.id !== sorting?.id ||
      prevSortingRef.current?.desc !== sorting?.desc

    if (sortingChanged) {
      shouldResetIndexHashRef.current = true
      defaultSortingChangedRef.current = true
      prevSortingRef.current = sorting
    }

    const tableMarkets: TableMarket[] = markets.map((market) => {
      const result = {
        ...market,
        favorite: favoriteMarkets.includes(market.name),
      }

      if (!sortedMarketsIndexHashRef.current.hasOwnProperty(getHashKey(result))) {
        shouldResetIndexHashRef.current = true
      }

      return result
    })

    if (shouldResetIndexHashRef.current) {
      sortedMarketsIndexHashRef.current = {}
      shouldResetIndexHashRef.current = false

      // Markets with the same "sorting" values will be sorted by name
      tableMarkets.sort((valueA, valueB) => {
        return valueA.name.localeCompare(valueB.name)
      })

      tableMarkets.sort(compareMarketsBySorting(sorting))

      if (!defaultSortingChangedRef.current) {
        tableMarkets.sort(compareMarketsByFavorite)
      }

      tableMarkets.forEach((market, idx) => {
        sortedMarketsIndexHashRef.current[getHashKey(market)] = idx
      })
    } else {
      tableMarkets.sort((valueA, valueB) => {
        const indexValueA = checkRequired(
          sortedMarketsIndexHashRef.current[getHashKey(valueA)],
          'indexValueA',
        )
        const indexValueB = checkRequired(
          sortedMarketsIndexHashRef.current[getHashKey(valueB)],
          'indexValueB',
        )

        return indexValueA - indexValueB
      })
    }

    return tableMarkets
  }, [favoriteMarkets, markets, sorting])

  return {
    [DefaultMarketCategory.All]: sortedMarkets,
    [DefaultMarketCategory.Favorites]: useMemo(
      () => sortedMarkets.filter((market) => market.favorite),
      [sortedMarkets],
    ),
    ...useMemo(() => groupBy(sortedMarkets, 'category'), [sortedMarkets]),
  } as Dictionary<TableMarket[]>
}
