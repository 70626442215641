import { useCallback } from 'react'

import { useFormatPercent as useFormatPercentKit } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'

export const PercentPrecision = {
  Slider: 0,
  TradingRules: 0,
  MarginSchedule: 1,
  FundingRate: 4,
} as const

export const useFormatPercent = () => {
  const formatPercent = useFormatPercentKit()

  return useCallback(
    (
      amount: number | Decimal | undefined,
      {
        precision = 2,
        showSymbol = true,
      }: { precision?: 0 | 1 | 2 | 4; showSymbol?: boolean } = {},
    ) => {
      if (amount === undefined) {
        return undefined
      }

      return formatPercent(amount, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        hidePercent: !showSymbol,
      })
    },
    [formatPercent],
  )
}
