import { get } from 'lodash'
import { type Row } from '@tanstack/react-table'

import { type Decimal } from '@x10/lib-core/utils'

/**
 * @param path Column ID (`row.getValue` doesn't work as `getValue` returns a result of a row accessor)
 */
export const sortDecimalsRows = <T>(rowA: Row<T>, rowB: Row<T>, path: string) => {
  const rowAValue = get(rowA.original, path) as Decimal
  const rowBValue = get(rowB.original, path) as Decimal

  if (rowAValue.gt(rowBValue)) {
    return 1
  }

  if (rowAValue.lt(rowBValue)) {
    return -1
  }

  return 0
}

export const compareDecimals = (valueA: Decimal, valueB: Decimal) => {
  if (valueA.gt(valueB)) {
    return 1
  }

  if (valueA.lt(valueB)) {
    return -1
  }

  return 0
}
