import type { Decimal } from '@x10/lib-core/utils'

import type { FundingRateInterval } from '../../types/funding-rate'

export const calcFundingRateForInterval = (
  fundingRate: Decimal,
  period: FundingRateInterval,
) => {
  switch (period) {
    case '1H':
      return fundingRate
    case '8H':
      return fundingRate.times(8)
    case '1Y':
      return fundingRate.times(365 * 8 * 3)
  }
}
