import { useMemo } from 'react'

import { useSuspendedMarkets } from '@src/domain/api/hooks/markets-info/use-markets'

import { DefaultMarketCategory } from '../../constants'

export const useMarketsCategories = () => {
  const { data: markets } = useSuspendedMarkets()

  return useMemo(
    () => [
      DefaultMarketCategory.All,
      DefaultMarketCategory.Favorites,
      ...Array.from(
        markets.reduce((acc, market) => {
          acc.add(market.category)

          return acc
        }, new Set<string>()),
      ).sort(),
    ],
    [markets],
  )
}
