import { type FC, type PropsWithChildren } from 'react'

import { Box, type BoxProps } from '@x10/lib-styled-system/jsx'

type NowrapTextProps = PropsWithChildren<BoxProps>

export const NowrapText: FC<NowrapTextProps> = ({ children, ...restProps }) => {
  return (
    <Box whiteSpace="nowrap" {...restProps}>
      {children}
    </Box>
  )
}
