import { useRef, useState, type FC } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnSort,
  type FilterFn,
  type Row,
  type SortingState,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'

import { getCryptoCurrencyInfo } from '@x10/lib-core/utils'
import { HStack, styled, type BoxProps } from '@x10/lib-styled-system/jsx'
import {
  Scrollable,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'
import { toRem } from '@x10/lib-ui-kit/utils'

import { type TableMarket } from '@src/domain/core/types/common'
import { useTableMarketsData } from '@src/domain/core/ui/hooks/use-table-markets-data'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

import { useColumns, type MarketsTableColumnArgs } from './markets-table.columns'

const nameFilter: FilterFn<TableMarket> = (row, columnId, value) => {
  if (columnId !== 'name') {
    return false
  }

  const marketName = row.original.name

  const { syntheticCode } = parseMarketName(marketName)
  const syntheticInfo = getCryptoCurrencyInfo(syntheticCode)

  const filterValueLowercased = value.toLowerCase()

  return (
    marketName.toLowerCase().includes(filterValueLowercased) ||
    syntheticInfo.name.toLowerCase().includes(filterValueLowercased)
  )
}

const ESTIMATED_ROW_HEIGHT = 48
const DEFAULT_SORTING: ColumnSort = {
  // should match column id
  id: 'marketStats.dailyVolume',
  desc: true,
}

const emptyData: TableMarket[] = []

type Props = MarketsTableColumnArgs & {
  containerProps?: BoxProps
  selectedCategory?: string
  search?: string
  onRowClick?: (row: TableMarket) => void
}

export const MarketsTable: FC<Props> = ({
  containerProps,
  selectedCategory = 'all',
  search,
  onRowClick,
  ...columnsArgs
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORTING])

  const tableMarketsData = useTableMarketsData(sorting[0] ?? DEFAULT_SORTING)
  const columns = useColumns(columnsArgs)

  const table = useReactTable({
    data: tableMarketsData[selectedCategory] ?? emptyData,
    columns,

    state: {
      sorting,
      globalFilter: search,
    },

    manualSorting: true,
    enableMultiSort: false,

    getRowId: (r) => r.name,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: (column) => column.id === 'name',
    globalFilterFn: nameFilter,

    onSortingChange: setSorting,
    onGlobalFilterChange: () => {},
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    overscan: 10,
    estimateSize: () => ESTIMATED_ROW_HEIGHT,
    getScrollElement: () => tableContainerRef.current,
  })

  const virtualRows = rowVirtualizer.getVirtualItems()
  const totalSize = rowVirtualizer.getTotalSize()

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const isRowOrMarketClickable = Boolean(onRowClick || columnsArgs.onMarketClick)

  return (
    <Scrollable scrollX scrollY ref={tableContainerRef} {...containerProps}>
      <styled.table css={{ w: '100%' }}>
        <styled.thead
          css={{
            position: 'sticky',
            top: 0,
            bg: columnsArgs.variant === 'trade-popover' ? 'token.grey-90' : 'token.grey',
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeaderCell
                    key={header.id}
                    css={{
                      width: header.getSize(),
                      cursor: header.column.getCanSort() ? 'pointer' : 'default',
                    }}
                    sortDirection={header.column.getIsSorted()}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <HStack gap="s-2">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <TableHeaderSortDirectionIcon
                        sortDirection={header.column.getIsSorted()}
                      />
                    </HStack>
                  </TableHeaderCell>
                )
              })}
            </tr>
          ))}
        </styled.thead>

        <styled.tbody fontSize="fs-12">
          {paddingTop > 0 && (
            <tr>
              <styled.td style={{ height: toRem(paddingTop) }} />
            </tr>
          )}

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<TableMarket>

            return (
              <styled.tr
                key={row.id + virtualRow.index}
                cursor={onRowClick ? 'pointer' : 'auto'}
                _hover={
                  isRowOrMarketClickable
                    ? {
                        '& > td': {
                          bg: 'token.white-10',
                        },
                        '& > td:first-of-type': {
                          borderRadius: '1rem 0 0 1rem',
                        },
                        '& > td:last-of-type': {
                          borderRadius: '0 1rem 1rem 0',
                        },
                      }
                    : undefined
                }
                onClick={() => onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </styled.tr>
            )
          })}

          {paddingBottom > 0 && (
            <tr>
              <styled.td style={{ height: toRem(paddingBottom) }} />
            </tr>
          )}
        </styled.tbody>
      </styled.table>
    </Scrollable>
  )
}
