import { type FC, type ReactNode } from 'react'

import { type CryptoCurrencyCode } from '@x10/lib-core/config'
import { getAssetUrl } from '@x10/lib-core/utils'
import { Logo } from '@x10/lib-ui-kit/components'

type CryptoLogoProps = {
  currency: CryptoCurrencyCode
  boxSize?: 24 | 32 | 40
  badge?: ReactNode
}

export const CryptoLogo: FC<CryptoLogoProps> = ({
  currency,
  boxSize = 24,
  badge,
  ...props
}) => {
  return (
    <Logo
      url={getAssetUrl({ type: 'crypto', name: currency === 'USD' ? 'USDC' : currency })}
      boxSize={boxSize}
      badge={badge}
      {...props}
    />
  )
}
