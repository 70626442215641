import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { type MarketName } from '@src/domain/api/x10/common'
import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'
import { PINNED_MARKETS_LIMIT } from '@src/domain/trade/constants'
import { useSelectedMarket } from '@src/domain/trade/store/market'

const emptyData: MarketName[] = []

const usePinnedMarketsStore = create<{
  selectedMarketIndex: number
  pinnedMarkets: MarketName[]
}>()(
  persist(
    () => ({
      selectedMarketIndex: 0,
      pinnedMarkets: emptyData,
    }),
    {
      name: getStorageKey('pinned-markets'),
    },
  ),
)

export const usePinnedMarkets = () => usePinnedMarketsStore()

usePinnedMarkets.removePinnedMarket = (removedIndex: number) => {
  usePinnedMarketsStore.setState((prev) => ({
    pinnedMarkets: prev.pinnedMarkets.filter((_market, index) => index !== removedIndex),
    selectedMarketIndex:
      prev.selectedMarketIndex >= removedIndex
        ? prev.selectedMarketIndex - 1
        : prev.selectedMarketIndex,
  }))
}

usePinnedMarkets.replacePinnedMarket = (
  pinnedMarket: MarketName,
  pinnedIndex: number,
) => {
  usePinnedMarketsStore.setState((prev) => ({
    ...prev,
    pinnedMarkets: prev.pinnedMarkets.map((market, index) =>
      index === pinnedIndex ? pinnedMarket : market,
    ),
  }))
}

usePinnedMarkets.setSelectedMarketIndex = (pinnedMarketIndex: number) => {
  usePinnedMarketsStore.setState((prev) => ({
    ...prev,
    selectedMarketIndex: pinnedMarketIndex,
  }))
}

usePinnedMarkets.clear = () =>
  usePinnedMarketsStore.setState({ pinnedMarkets: emptyData })

const replaceCurrentlySelectedMarket = (
  selectedMarketName: MarketName,
  newPinnedMarketName: MarketName,
  pinnedMarkets: MarketName[],
) =>
  pinnedMarkets.map((pinnedMarketName) =>
    pinnedMarketName === selectedMarketName ? newPinnedMarketName : pinnedMarketName,
  )

export const useAddPinnedMarket = () => {
  const market = useSelectedMarket()
  const { pinnedMarkets } = usePinnedMarkets()

  return useCallback(
    (newPinnedMarketName: MarketName) => {
      if (newPinnedMarketName === market.name) {
        return
      }
      const pinnedMarketIndex = pinnedMarkets.findIndex(
        (pinnedMarketName) => pinnedMarketName === newPinnedMarketName,
      )

      if (pinnedMarketIndex > -1) {
        usePinnedMarkets.setSelectedMarketIndex(pinnedMarketIndex)
        return
      }

      if (pinnedMarkets.length >= PINNED_MARKETS_LIMIT) {
        const currentMarketIndex = pinnedMarkets.findIndex(
          (pinnedMarketName) => pinnedMarketName === market.name,
        )

        usePinnedMarketsStore.setState((prev) => ({
          ...prev,
          pinnedMarkets: replaceCurrentlySelectedMarket(
            market.name,
            newPinnedMarketName,
            pinnedMarkets,
          ),
        }))

        usePinnedMarkets.setSelectedMarketIndex(currentMarketIndex || 0)

        return
      }

      usePinnedMarketsStore.setState((prev) => ({
        ...prev,
        pinnedMarkets: [
          ...(isEmpty(prev.pinnedMarkets) ? [market.name] : prev.pinnedMarkets),
          newPinnedMarketName,
        ],
      }))

      usePinnedMarkets.setSelectedMarketIndex(pinnedMarkets.length || 1) // we select latest market which was pinned above (in case of no pinned markets, we select the first one pinned)
    },
    [market.name, pinnedMarkets],
  )
}
