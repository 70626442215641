import { type FC } from 'react'

import { SvgIcon, Switch } from '@x10/lib-ui-kit/components'
import { type IconSize } from '@x10/lib-ui-kit/types'

import { type MarketName } from '@src/domain/api/x10/common'
import { useFavoriteMarkets } from '@src/domain/core/store/use-favorite-markets'

type Props = {
  favorite: boolean
  marketName: MarketName
  size?: IconSize
}

export const FavoriteMarketButton: FC<Props> = ({ favorite, marketName, size }) => (
  <Switch.Root
    css={{
      color: 'token.white-50',
      position: 'static',
    }}
    checked={favorite}
    onClick={(event) => {
      event.stopPropagation()
      event.preventDefault()

      if (favorite) {
        useFavoriteMarkets.removeFavoriteMarket(marketName)
        return
      }

      useFavoriteMarkets.addFavoriteMarket(marketName)
    }}
  >
    <Switch.Control
      css={{
        bg: 'transparent',
        width: 'auto',
        p: '0',
        _checked: {
          bg: 'transparent',
        },
      }}
    >
      {favorite ? (
        <SvgIcon.SvgIconStarFilled size={size} />
      ) : (
        <SvgIcon.SvgIconStarEmpty size={size} />
      )}
    </Switch.Control>
    <Switch.Label display="none">Mark {marketName} as favorite</Switch.Label>
    <Switch.HiddenInput />
  </Switch.Root>
)
