import type { FC, PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Portal, Tooltip, ValueColored } from '@x10/lib-ui-kit/components'

import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { calcFundingRateForInterval } from '@src/domain/trade/utils/calc/calc-funding-rate-for-interval'

type FundingRateTooltipProps = PropsWithChildren<{
  rate: Decimal
}>

export const FundingRateTooltip: FC<FundingRateTooltipProps> = ({ rate, children }) => {
  const formatPercent = useFormatPercent()

  return (
    <Tooltip.Root
      positioning={{ placement: 'top-start' }}
      lazyMount
      openDelay={250}
      closeDelay={50}
    >
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>

      <Portal>
        <Tooltip.Positioner>
          <Tooltip.Content css={{ minW: 'unset' }}>
            <HStack css={{ gap: 's-32' }}>
              <div>
                <ValueColored>
                  {formatPercent(calcFundingRateForInterval(rate, '8H'), {
                    precision: PercentPrecision.FundingRate,
                  })}
                </ValueColored>
                <Box css={{ textStyle: 'small', color: 'token.white-50' }}>
                  <FormattedMessage
                    id="workspace.markets.widget.markets-table.column.funding-rate.tooltip.8-hours.title"
                    defaultMessage="8 hours"
                  />
                </Box>
              </div>

              <div>
                <ValueColored>
                  {formatPercent(calcFundingRateForInterval(rate, '1Y'), {
                    precision: PercentPrecision.FundingRate,
                  })}
                </ValueColored>
                <Box css={{ textStyle: 'small', color: 'token.white-50' }}>
                  <FormattedMessage
                    id="workspace.markets.widget.markets-table.column.funding-rate.tooltip.annualized.title"
                    defaultMessage="Annualized"
                  />
                </Box>
              </div>
            </HStack>
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  )
}
