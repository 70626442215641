import { useMemo } from 'react'
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'

import { useIntl } from '@x10/lib-core/i18n'
import { getCryptoCurrencyInfo } from '@x10/lib-core/utils'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import { SvgIcon, TableCell, ValueColored } from '@x10/lib-ui-kit/components'

import { type CachedMarket } from '@src/domain/api/x10'
import { type MarketName } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { type TableMarket } from '@src/domain/core/types/common'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { FavoriteMarketButton } from '@src/domain/core/ui/components/favorite-market-button'
import { FundingRateTooltip } from '@src/domain/core/ui/components/markets-table/funding-rate-tooltip'
import { NowrapText } from '@src/domain/core/ui/components/nowrap-text'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

const columnHelper = createColumnHelper<TableMarket>()

export type MarketsTableColumnArgs =
  | {
      variant: 'trade-popover'
      selectedMarket: CachedMarket
      onMarketClick?: never
    }
  | {
      variant: 'standalone-page'
      selectedMarket?: never
      onMarketClick?: (market: MarketName) => void
    }

export const useColumns = ({
  variant,
  selectedMarket,
  onMarketClick,
}: MarketsTableColumnArgs) => {
  const { formatMessage } = useIntl()

  const isStandalonePageMarketsTable = variant === 'standalone-page'

  const formatMarketAsset = useFormatMarketAsset({
    showSymbol: isStandalonePageMarketsTable,
  })
  const formatPercent = useFormatPercent()

  return useMemo(
    () =>
      [
        columnHelper.accessor(
          (row) => {
            const marketName = row.name
            const isFavoriteMarket = row.favorite

            const { syntheticCode } = parseMarketName(marketName)
            const syntheticInfo = getCryptoCurrencyInfo(syntheticCode)

            return (
              <TableCell>
                <HStack gap="s-16">
                  <FavoriteMarketButton
                    favorite={isFavoriteMarket}
                    marketName={marketName}
                    size={16}
                  />

                  <HStack
                    css={{
                      gap: 's-16',
                      cursor: onMarketClick ? 'pointer' : 'inherit',
                    }}
                    onClick={() => onMarketClick?.(marketName)}
                  >
                    <CryptoLogo
                      currency={syntheticCode}
                      boxSize={24}
                      {...(variant === 'trade-popover' &&
                        selectedMarket.name === marketName && {
                          badge: <SvgIcon.SvgIconCheckmarkBadge size={16} />,
                        })}
                    />

                    <VStack
                      css={{
                        gap: '0',
                        alignItems: 'start',
                      }}
                    >
                      <NowrapText lineHeight="1rem">{marketName}</NowrapText>

                      <Box lineHeight="1rem">{syntheticInfo.name}</Box>
                    </VStack>
                  </HStack>
                </HStack>
              </TableCell>
            )
          },
          {
            header: formatMessage({
              id: 'workspace.markets.widget.markets-table.column.market.title',
              defaultMessage: 'Market',
            }),
            id: 'name',
            cell: (row) => row.renderValue(),
            sortDescFirst: false,
            minSize: 184,
          },
        ),
        columnHelper.accessor(
          (row) => (
            <TableCell>
              {formatMarketAsset(
                { amount: row.marketStats.lastPrice, type: 'collateral' },
                { marketName: row.name },
              )}
            </TableCell>
          ),
          {
            id: 'marketStats.lastPrice',
            header: formatMessage({
              id: 'workspace.markets.widget.markets-table.column.last-price.title',
              defaultMessage: 'Last Price',
            }),
            cell: (props) => props.renderValue(),
            minSize: 168,
          },
        ),
        isStandalonePageMarketsTable &&
          columnHelper.accessor(
            (row) => (
              <TableCell>
                {formatMarketAsset(
                  { amount: row.marketStats.markPrice, type: 'collateral' },
                  { marketName: row.name },
                )}
              </TableCell>
            ),
            {
              id: 'marketStats.markPrice',
              header: formatMessage({
                id: 'workspace.trade.widget.chart.market-selector.column.mark-price.title',
                defaultMessage: 'Mark Price',
              }),
              cell: (props) => props.renderValue(),
              minSize: 168,
            },
          ),
        columnHelper.accessor(
          (row) => {
            const changePercent = row.marketStats.dailyPriceChangePercentage

            return (
              <TableCell>
                <ValueColored>{formatPercent(changePercent)}</ValueColored>
              </TableCell>
            )
          },
          {
            id: 'dailyPriceChange',
            header: formatMessage({
              id: 'workspace.markets.widget.markets-table.column.change-24.title',
              defaultMessage: '24h Change',
            }),
            cell: (row) => row.renderValue(),
            minSize: 168,
          },
        ),
        columnHelper.accessor(
          (row) => (
            <TableCell>
              <FundingRateTooltip rate={row.marketStats.fundingRate}>
                <ValueColored css={{ display: 'inline-block' }}>
                  {formatPercent(row.marketStats.fundingRate, {
                    precision: PercentPrecision.FundingRate,
                  })}
                </ValueColored>
              </FundingRateTooltip>
            </TableCell>
          ),
          {
            id: 'marketStats.fundingRate',
            header: formatMessage({
              id: 'workspace.markets.widget.markets-table.column.funding-rate.title',
              defaultMessage: 'Funding Rate',
            }),
            cell: (props) => props.renderValue(),
            minSize: 168,
          },
        ),
        isStandalonePageMarketsTable &&
          columnHelper.accessor(
            (row) => (
              <TableCell>
                <div>
                  {formatMarketAsset(
                    { amount: row.marketStats.openInterest, type: 'collateral' },
                    { marketName: row.name, precision: AssetPrecision.OpenInterest },
                  )}
                </div>
                <div>
                  {formatMarketAsset(
                    { amount: row.marketStats.openInterestBase, type: 'synthetic' },
                    { marketName: row.name, precision: AssetPrecision.OpenInterest },
                  )}
                </div>
              </TableCell>
            ),
            {
              id: 'marketStats.openInterest',
              header: formatMessage({
                id: 'workspace.markets.widget.markets-table.column.open-interest.title',
                defaultMessage: 'Open Interest',
              }),
              cell: (row) => row.renderValue(),
              size: 164,
            },
          ),
        columnHelper.accessor(
          (row) => (
            <TableCell>
              <div>
                {formatMarketAsset(
                  { amount: row.marketStats.dailyVolume, type: 'collateral' },
                  {
                    marketName: row.name,
                    precision: AssetPrecision.VolumeStat,
                    showSymbol: true,
                  },
                )}
              </div>
              <div>
                {formatMarketAsset(
                  { amount: row.marketStats.dailyVolumeBase, type: 'synthetic' },
                  {
                    marketName: row.name,
                    precision: AssetPrecision.VolumeStat,
                    showSymbol: true,
                  },
                )}
              </div>
            </TableCell>
          ),
          {
            id: 'marketStats.dailyVolume',
            header: formatMessage({
              id: 'workspace.markets.widget.markets-table.column.volume-24.title',
              defaultMessage: '24h Volume',
            }),
            cell: (row) => row.renderValue(),
            size: 168,
          },
        ),
        isStandalonePageMarketsTable &&
          columnHelper.accessor(
            (row) => (
              <TableCell>
                {formatMarketAsset(
                  { amount: row.marketStats.dailyHigh, type: 'collateral' },
                  { marketName: row.name },
                )}
              </TableCell>
            ),
            {
              id: 'marketStats.dailyHigh',
              header: formatMessage({
                id: 'workspace.markets.widget.markets-table.column.high-24.title',
                defaultMessage: '24h High',
              }),
              cell: (row) => row.renderValue(),
              maxSize: 168,
            },
          ),
        isStandalonePageMarketsTable &&
          columnHelper.accessor(
            (row) => (
              <TableCell>
                {formatMarketAsset(
                  { amount: row.marketStats.dailyLow, type: 'collateral' },
                  { marketName: row.name },
                )}
              </TableCell>
            ),
            {
              id: 'marketStats.dailyLow',
              header: formatMessage({
                id: 'workspace.markets.widget.markets-table.column.low-24.title',
                defaultMessage: '24h Low',
              }),
              cell: (row) => row.renderValue(),
              maxSize: 168,
            },
          ),
      ].filter(Boolean) as Array<ColumnDef<TableMarket>>,
    [
      formatMarketAsset,
      formatMessage,
      formatPercent,
      isStandalonePageMarketsTable,
      onMarketClick,
      selectedMarket?.name,
      variant,
    ],
  )
}
